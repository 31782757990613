<template>
	<v-dialog v-model="isOpenEventDetailDialog" fullscreen>
		<v-row no-gutters>
			<v-col cols="12">
				<v-card class="pa-4" elevation="0">
					<v-card-title class="pa-0">
						교육 정보
						<v-spacer />
						<v-btn
							icon
							@click="$emit('update:is-open-event-detail-dialog', false)"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>강의 소개</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">
											<div style="line-height: 25px">
												다양한 IT 서비스의 기능과 특징에 대해 배우고
												<br />
												각각의 IT 서비스를 완성하기까지의
												<br />
												전반적인 과정에 대해 배웁니다
											</div>
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="text-wrap">
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>강의 대상자</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">
											IT 마케터로서 무한한 IT 세상 에 관심있는 20대 이상
											성인이라면 누구나
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="text-wrap">
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>학습 커리큘럽</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">
											<ol type="I">
												<li class="mb-6">
													<dt>서비스 개발</dt>
												</li>
												<li class="mb-6">
													<dt>다양한 서비스</dt>
												</li>
												<li class="mb-4">
													<dt>관련 기술</dt>
												</li>
											</ol>
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title class="text-wrap">
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>진행 방법</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">
											<dl class="mb-10">
												<dt>
													<v-icon size="20" color="success" class="mr-1">
														mdi-check
													</v-icon>
													교육 신청 후, 온라인으로 모든 강의를 수강합니다
												</dt>
												<dd class="text-sm">
													온라인 학습에 필요한 자료는 교육시 다운로드 받을 수
													있게 제공됩니다.
												</dd>
											</dl>
											<dl>
												<dt>
													<v-icon size="20" color="success" class="mr-1">
														mdi-check
													</v-icon>
													교육 수료 기준에 부합하게 되면, IT 마케터 자격 검정
													시험 자격이 주어집니다
												</dt>
												<dd class="text-sm">
													IT 마케터 교육은 온라인으로 진행되며 교육 수강 이후
													자격 시험을 치루게 됩니다.
													<br />
													자격 시험 공지는 별도로 신청보드를 통해 업로드 됩니다
												</dd>
											</dl>
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>최대 강의 개월수</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">0.1개월</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<v-row no-gutters>
										<v-col
											cols="12"
											md="3"
											sm="12"
											:class="{ 'mb-3': !isSmAndUp }"
										>
											<strong>강의자 소개</strong>
										</v-col>
										<v-col cols="12" md="9" sm="12">
											<dl>
												<dt class="mb-2">이원용</dt>
												<dd>포도나무랩 대표</dd>
											</dl>
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-card-actions class="px-0 d-flex flex-column mt-6">
						<div class="button-wrapper">
							<v-btn
								class="button apply mb-1"
								color="primary"
								@click="$emit('update:is-open-event-detail-dialog', false)"
							>
								닫기
							</v-btn>
						</div>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
	</v-dialog>
</template>

<script>
import { computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

export default {
	props: {
		isOpenEventDetailDialog: {
			type: Boolean,
			required: true,
		},
	},
	setup() {
		const $vuetify = getVuetify()

		const isSmAndUp = computed(() => {
			return $vuetify.breakpoint.smAndUp
		})

		return {
			isSmAndUp,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
