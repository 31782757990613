<template>
	<div class="px-3">
		<v-row class="mt-1 mb-2">
			<div class="d-flex align-center">
				<router-link
					:to="{
						name: 'mypage-market-item',
						params: {
							itemId: item.id,
						},
					}"
					class="text-decoration-none"
				>
					<h3 style="font-weight: 500">
						<v-icon style="position: relative; bottom: 2px">
							mdi-card-multiple-outline
						</v-icon>
						상품
					</h3>
				</router-link>
				<span class="mx-1">&#62;</span>
				<h3>주문하기</h3>
			</div>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>교육 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
					</v-list>

					<v-card-actions class="px-0 d-flex flex-column">
						<div class="button-wrapper">
							<v-btn
								class="button apply"
								color="primary"
								@click="isOpenEventDetailDialog = !isOpenEventDetailDialog"
							>
								자세히 알아보기
							</v-btn>
						</div>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>주문 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									타이틀
								</v-list-item-subtitle>
								<v-list-item-title v-if="item && item.title">
									{{ item.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									내용
								</v-list-item-subtitle>
								<v-list-item-title v-if="item && item.body" class="text-wrap">
									<span v-html="item.body" class="body-content"></span>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle>장소</v-list-item-subtitle>
								<v-list-item-title v-if="item && item.location">
									{{ item.location }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-divider class="my-3" />
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">이름</v-list-item-subtitle>
							<v-list-item-title>{{ profile.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								이메일
							</v-list-item-subtitle>
							<v-list-item-title>{{ profile.email }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								휴대폰번호
							</v-list-item-subtitle>
							<v-list-item-title>{{ profile.phoneNumber }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>가격 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs text-wrap">
									가격
								</v-list-item-subtitle>
								<v-list-item-title v-if="item" class="text-wrap">
									<div v-if="item.amount > 0">
										<span class="amount primary--text">
											{{ item.amount | commaFormat }} 원
										</span>
										<span :class="{ 'd-block text-sm': isSmAndDown }">
											(교육비 24만원 + 검정료 4만원 + 교재비 1만원)
										</span>
									</div>
									<div v-else>
										<div class="mb-5">무료</div>
										<v-btn class="button apply mb-1" color="secondary">
											신청보드에서 신청 가능합니다
										</v-btn>
										<div>
											<v-btn
												class="button apply"
												color="error"
												@click="cancelOrderItem(item)"
											>
												canCancel
											</v-btn>
										</div>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-card-actions class="px-0 d-flex flex-column">
						<v-btn
							class="button apply mb-1"
							color="primary"
							@click="orderMarketItem(item)"
						>
							주문하기
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-col>
		</v-row>
		<my-page-application-board-event-detail-dialog
			:is-open-event-detail-dialog.sync="isOpenEventDetailDialog"
		/>
	</div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'

import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { confirmSwal, warningSwal } from '@/plugins/swalMixin'

import MarketItemService from '@/services/MarketItemService'

import MyPageApplicationBoardEventDetailDialog from '../MyPageApplicationBoardEventDetailDialog.vue'

export default {
	components: {
		MyPageApplicationBoardEventDetailDialog,
	},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const item = ref({})
		const isLoading = ref(false)
		const profile = computed(() => store.getters['user/getMeDetail'])
		const itemId = route.value.params.itemId
		const isOpenEventDetailDialog = ref(false)

		const getMarketItem = async () => {
			try {
				const data = await MarketItemService.getMarketItem(itemId)

				if (!data.visible || !data.isActive) {
					router.go(-1)
					return
				}

				item.value = data
			} catch (e) {
				await warningSwal('잘못된 요청입니다')
				router.go(-1)
			}
		}
		getMarketItem()

		const orderMarketItem = async item => {
			const confirm = await confirmSwal(
				` ${item.title} 상품을 주문 하시겠습니까?`,
			)
			if (confirm.isConfirmed) {
				try {
					const orderedData = await MarketItemService.orderMarketItem(item.id)
					router.push({
						name: 'mypage-market-order-payment',
						params: {
							orderId: orderedData.marketOrderId,
						},
					})
				} catch (e) {
					warningSwal(
						e.response.status === 400
							? e.response.data.message
							: '상품을 주문하는데 문제가 발생했습니다.',
					)
				}
			}
		}

		const isCiVerified = computed(() => {
			return profile.value && !profile.value?.ciVerified
		})

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		onMounted(() => {
			if (isCiVerified.value) {
				router.push({ name: 'auth-phone-number' })
			}
		})

		return {
			item,
			profile,
			isLoading,
			isSmAndDown,
			isOpenEventDetailDialog,

			orderMarketItem,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
